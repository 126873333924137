<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="Expenses">
			<b-col cols="12" xl="12">
				<div class="empty-state" v-if="MyExpObj.length === 0">
					<p>{{ this.FormMSG(10, 'No expense to validate.') }}</p>
				</div>
				<div v-if="$screen.width < 992">
					<b-overlay :show.sync="loadingMobile" opacity="0.75" rounded="lg">
						<MobileViewValidateExpenses
							:expenses="MyExpObj"
							:fields="expFields"
							@mobile-view-validate-expense:clicked="rowClicked"
							@mobile-view-validate-expense:validated="validateItem"
						/>
					</b-overlay>
				</div>
				<div class="container-layout details-document-package p-0" v-else>
					<b-card no-body class="card-border-blue-light" v-for="oneItem in MyExpObj" :key="oneItem.depName">
						<b-row class="back-with-title cursor-pointer d-flex align-items-center pb-2 pl-0" :class="{ 'mt-16': $isPwa() }">
							<b-col cols="6">
								<h2 class="d-flex align-items-center">
									<div>{{ oneItem.depName }}</div>
									<b-badge pill class="ml-2">{{ oneItem.numExpForDep }}</b-badge>
								</h2>
							</b-col>
							<b-col cols="6">
								<div class="card-header-actions">
									<b-link class="card-header-action btn-minimize text-white" v-b-toggle="oneItem.depName">
										<i class="icon-arrow-down"></i>
									</b-link>
								</div>
							</b-col>
						</b-row>
						<b-collapse visible :id="oneItem.depName">
							<b-card-body>
								<div v-for="oneUser in oneItem.users" :key="oneUser.fullName">
									<h5 class="d-flex align-items-center">
										{{ oneUser.fullName }} - {{ oneUser.functionName }}
										<b-badge pill class="ml-2">{{ oneUser.numExpForUser }}</b-badge>
									</h5>
									<b-table
										:hover="hover"
										responsive="sm"
										:items="oneUser.exp"
										text-variant="white"
										:fields="expFields"
										:current-page="currentPage"
										sticky-header="700px"
										:per-page="perPage"
										@row-clicked="rowClicked"
										:head-variant="hv"
										bordered
										small
									>
										<template v-slot:cell(validatedStatus)="data">
											<div class="d-flex flex-row w-100" style="gap: 5px">
												<div
													v-if="data.item.allExpenseItemIsInContract === false"
													class="d-flex justify-content-end"
													style="width: 40%"
												>
													<button
														class="btn-transparent text-color-rhapsody-in-blue"
														v-b-tooltip.left.html
														:title="tooltipContentOvertime()"
													>
														<component :is="getLucideIcon('AlertTriangle')" color="#EA4E" :size="18" />
													</button>
												</div>
												<div v-else class="d-flex justify-content-end" style="width: 50%"></div>
												<div class="d-flex justify-content-start wrap-status">
													<div :class="`status ${classStatus(data.item.validated)}`" style="font-size: 0.7rem">
														{{ data.item.validatedStatus }}
													</div>
												</div>
											</div>
										</template>
										<template v-slot:cell(encodedBy)="data">
											<div class="d-flex flex-row justify-content-center align-items-center">
												{{ encodedBy(data.item) }}
											</div>
										</template>
										<!-- <template slot="val" slot-scope="data"> -->
										<template v-slot:cell(val)="data">
											<b-button class="btn bg-transparent border-0 ml-1" size="sm" @click="validateItem(data.item.id)">
												<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :color="ICONS.CHECK_SQUARE.color" :size="20" />
											</b-button>
										</template>
									</b-table>
								</div>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-col>
			<b-modal
				header-class="header-class-modal-doc-package"
				:title="FormMSG(11, 'Success!')"
				class="modal-success"
				v-model="successModal"
				@ok="successModal = false"
				ok-variant="success"
				ok-only
				modal-class="mui-animation"
				:fade="false"
			>
				{{ this.FormMSG(12, 'The Expense has been validated!') }}
			</b-modal>
		</b-row>
	</errorContainer>
</template>

<script>
import { store } from '../../store';
import { rendCurrency, rendKgCo2 } from '~helpers';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import MobileViewValidateExpenses from '@/components/MobileViews/ValidateExpenses/Main';
import { mapActions } from 'vuex';
import { classStatus } from '@/shared/global-status';
import moment from 'moment';
import globalMixin from '@/mixins/global.mixin';
import { validateExpense, getExpenseToValidate } from '@/cruds/expense.crud';

export default {
	name: 'ValidateExpenses',
	mixins: [languageMessages, globalMixin],
	props: {
		caption1: {
			type: String,
			default: 'My Expenses'
		},
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		MobileViewValidateExpenses
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			Expenses: [],
			MyExpObj: [
				{
					depName: '',
					numExpForDep: 0,
					users: [
						{
							fullName: '',
							numExpForUser: 0,
							exp: []
						}
					]
				}
			],
			MyExp: {
				depName: '',
				numExpForDep: 0,
				users: [
					{
						fullName: '',
						numExpForUser: 0,
						exp: []
					}
				]
			},
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			isLoading: false,
			curExp: {
				id: 0,
				date: '',
				type: 0,
				amountTotal: 0,
				validated: 0,
				validatedStatus: 'Not Submited',
				typeString: ''
			},
			loadingMobile: false
		};
	},
	async created() {
		if (this.curExp.date.length === 0) {
			this.firstTimeInitialisation();
		}
		await this.reloadData();
	},
	computed: {
		expFields() {
			return [
				{
					key: 'id',
					label: '#' + this.FormMSG(21, 'Id'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(20, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(20, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				this.encodedByField,
				{
					key: 'typeString',
					label: this.FormMSG(22, 'Type'),
					formatter: (value) => {
						return value;
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'date',
					label: this.FormMSG(23, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'departmentName',
					label: this.FormMSG(24, 'Department'),
					formatter: (value) => {
						return value.toLowerCase();
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					class: 'text-right',
					sortable: true
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(25, 'Amount'),
					formatter: (value) => rendCurrency(value),
					sortable: true,
					class: 'text-right'
				},

				{
					key: 'val',
					label: this.FormMSG(26, 'Validate'),
					formatter: (v) => v,
					class: 'text-center',
					isSlot: true
				}
			];
		},
		styleObject: function () {
			let myObj = {
				color: 'black',
				fontSize: '14px'
			};
			if (this.curExp.validated == 1) {
				myObj.color = 'blue';
			} else if (this.curExp.validated == 2) {
				myObj.color = 'yellow';
			} else if (this.curExp.validated == 8) {
				myObj.color = 'green';
			} else if (this.curExp.validated == 4 || this.curExp.validated == 16) {
				myObj.color = 'red';
			}
			return myObj;
		},
		encodedByField() {
			if (this.useEntryForCrewExpenses) {
				return {
					key: 'encodedBy',
					label: this.FormMSG(29, 'Encoded By'),
					sortable: true,
					class: 'text-center'
				};
			}
		},
		useEntryForCrewExpenses() {
			return store.state.myProject.useEntryForCrewExpenses;
		}
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		encodedBy(data) {
			return `${data.encoder.name} ${data.encoder.firstName}`;
		},
		tooltipContentOvertime() {
			let message = `${this.FormMSG(
				28,
				"This expense sheet contains expense  items on a date outside the user's contract dates. You can still validate it."
			)}`;
			return message;
		},
		async validateItem(id) {
			const action = async () => {
				const expenseId = +id;

				await validateExpense(expenseId, '')
					.then(async (record) => {
						await this.getNotifications();
						await this.reloadData();
						this.successModal = true;
						this.fillMyExpObj();
					})
					.catch((error) => {
						console.log(error);
					});
			};
			await this.confirmModal(action, this.FormMSG(6000, 'Do you really want to validate this item?'));
		},

		fillMyExpObj() {
			// scan Expenses and fill MyExpObj structure
			let depNam = 'ft';
			let userTmp = {
				fullName: '',
				functionName: '',
				numExpForUser: 0,
				exp: []
			};
			this.MyExpObj = [];
			if (this.Expenses.length > 0) {
				for (let i = 0; i < this.Expenses.length; i++) {
					if (depNam == 'ft') {
						depNam = this.Expenses[i].departmentName;
						// create Object
						this.MyExpObj = [];
						this.MyExp.depName = this.Expenses[i].departmentName;
						this.MyExp.numExpForDep = 0;
						this.MyExp.users = [];
						userTmp.fullName = this.Expenses[i].fullName;
						userTmp.functionName = this.Expenses[i].functionName;
					} else if (this.Expenses[i].departmentName != this.MyExp.depName) {
						// department change and user change, store MyExp in array and reset my Object
						// push first current user to array
						const objTmp = Object.assign({}, userTmp);
						this.MyExp.users.push(objTmp);
						// reset userTmp
						userTmp.fullName = this.Expenses[i].fullName;
						userTmp.functionName = this.Expenses[i].functionName;
						userTmp.numExpForUser = 0;
						userTmp.exp = [];
						const objTmp2 = Object.assign({}, this.MyExp);
						this.MyExpObj.push(objTmp2);
						this.MyExp.depName = this.Expenses[i].departmentName;
						this.MyExp.users = [];
						this.MyExp.numExpForDep = 0;
					} else if (this.Expenses[i].fullName != userTmp.fullName) {
						// add this expense to user
						// push first current user to array
						const objTmp3 = Object.assign({}, userTmp);
						this.MyExp.users.push(objTmp3);
						// reset userTmp
						userTmp.fullName = this.Expenses[i].fullName;
						userTmp.functionName = this.Expenses[i].functionName;
						userTmp.numExpForUser = 0;
						userTmp.exp = [];
					}
					// add this expense to current user
					this.MyExp.numExpForDep++;
					userTmp.numExpForUser++;
					const objTmp4 = Object.assign({}, this.Expenses[i]);
					userTmp.exp.push(objTmp4);
				}
				// push last user to array
				const objTmp5 = Object.assign({}, userTmp);
				this.MyExp.users.push(objTmp5);
				// push last MyExp to MyExpObj
				const objTmp6 = Object.assign({}, this.MyExp);
				this.MyExpObj.push(objTmp6);
				//console.log("this.MyExpObj:",this.MyExpObj);
			}
		},

		getCurrentDate() {
			const currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},

		firstTimeInitialisation() {},

		updateTZdataEach(expData) {
			// setting row color
			expData.validatedStatus = this.validatedText(expData.validated);
			expData.typeString = this.getTypeText(expData.type);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			//expData._rowVariant = this.validatedColor(expData.validated);
			expData.validatedClass = this.validatedColor(expData.validated);
			expData.statusClass = classStatus(expData.validated);
			if (expData.user.name.length == 0 && expData.user.firstName.length == 0) {
				expData.fullName = expData.email;
			} else {
				expData.fullName = expData.user.name + ' ' + expData.user.firstName;
			}
			expData.functionName = expData.functionName.toLowerCase();
		},

		getTypeText(type) {
			//
			// returns the label text corresponding to the validated value
			return this.GetTextFromMenuNumberAndMenuValue(1003, type);
		},

		validatedText(validated) {
			// returns the label text corresponding to the validated value
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},

		validatedColor(validated) {
			let retval = '';

			if (validated == 1) {
				retval = 'primary';
			} else if (validated == 2) {
				retval = 'warning';
			} else if (validated == 8) {
				retval = 'success';
			} else if (validated == 4 || validated == 16) {
				retval = 'danger';
			} else {
				retval = 'grey';
			}

			return retval;
		},

		async reloadData() {
			this.isLoading = true;
			await getExpenseToValidate()
				.then((records) => {
					records.forEach(this.updateTZdataEach);
					this.Expenses = records;
					this.fillMyExpObj();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => (this.isLoading = false));
		},

		getRowCount(items) {
			//console.log(items)
			return items.length;
		},

		expDetailLink(id) {
			return `/validateExp/${id.toString()}`;
		},

		rowClicked(item) {
			// copy item content into curExp structure.
			//   console.log("item :", item);
			store.setCurExpense(item);

			const expLink = this.expDetailLink(item.id);
			//  console.log("rowclicked starting:",expLink);
			this.$router.push({
				path: expLink
			});
		},
		classStatus(validated) {
			return classStatus(validated);
		}
	}
};
</script>
